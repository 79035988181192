import { useEffect, useState } from "react";
import styled from "styled-components";
import MessageModal from "../MessageModal";
import Button from "../Button";
import { userContext } from '../../usercontext';
import Input from "../InputComp";
import { fetchWrapper } from "../../utils/apiHandlers";
import { validateEmail, formatPhoneInput } from "../../utils/helperfunctions";
import { MdContentCopy } from "react-icons/md";
import TextboxComp from "../TextboxComp";
import { useHistory } from "react-router-dom";

interface Props{
}

const Billing: React.FC<Props> = (props) => {
    const history = useHistory()
  
  const [user, setUser] = useState<any>(null) 
  const [ firstname, setfirstname ] = useState("")
  const [ lastname, setlastname ] = useState("")
  const [ phonenumber, setphonenumber ] = useState("")
  const [ address, setaddress ] = useState("")
  
  
  const [ secretkey, setsecretkey ] = useState("")
  const installpackage = "npm i miamigochatbot"

  const usecomponent = `  import Miamigochatbot from "miamigochatbot"

  <Miamigochatbot 
    userid="12224"
    supportEmail="support@example.com"
    chatbotname="Support"
    title='AI Chatbot' 
    onSend=(question)=>handle(question) 
    />`

  const [modalheader, setmodalheader] = useState('')
  const [modalmessage, setmodalmessage] = useState('')
  const [modalprogress, setmodalprogress] = useState(false)  
  const [modalstate, setmodalstate] = useState(false)
  const [pwdsent, setpwdsent] = useState(false)

  const openModal = (header: string, message: string, progress?: boolean) => {
    setmodalheader(header)
    setmodalmessage(message)
    setmodalstate(true)
    setmodalprogress(progress?progress: false)
    
  }

  const update = async () => {
    openModal("Message", "updating", true)
    try{
        let response = await userContext.updateprofile(user.email, firstname, lastname, phonenumber, address)
        if(response){
            openModal("Message", "Profile updated.", false)
        }else{
            openModal("Error", "Failed.", false)
        }

    }catch(error){
        openModal("Error", "Failed.", false)
    }
  }

  const changephonenumber = (e: any) =>{
    if(e.target.value.length>12)return
    const re = /^[0-9\b]+$/;
    let newTxt = e.target.value.replace(/-/g, "");
    let phone = "";
    if (newTxt === "" || re.test(newTxt)) {
      phone = formatPhoneInput(newTxt, [4, 8]);
      setphonenumber(phone);
    }
  }

  const showkey = async () =>{
    let response = await fetchWrapper.post("/api/authenticate/showsecretkey", {userid: user.userid})
    if(response.status==200){
      setsecretkey(response.secret)
    }else{
      openModal("Warning", "Your secret key cannot be revealed. If you lost your secret key you may generate a new key.")
      return
    }
    openModal("Message", "Copy and save your secret key it will not be revealed again.")

  }

  const getnewkey = async () => {
    let response = await fetchWrapper.post("/api/authenticate/gennewsecretkey", {userid: user.userid})
    if(response.status==200){
      setsecretkey(response.secret)
    }
    openModal("Message", "Copy and save your secret key it will not be revealed again.")

  }

  useEffect(() => {
    const subscription = userContext.user.subscribe((x:any) => setUser(x))
    return () => subscription.unsubscribe()
  }, [])

  useEffect(()=>{
    if(!user)return;
    if(user.status!==200){
      history.push("/login")
    }
    setfirstname(user.firstname)
    setlastname(user.lastname)
    setphonenumber(user.phonenumber)
    setaddress(user.address)
  },[user])

  return (
    <>
      <MainContainer>
        <Header>Billing</Header>
        <SubHeader>Free subscription</SubHeader>
      </MainContainer>
    </>
  );
};

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`

const Header = styled.div`
    padding: 10px;
    font-weight: 700;
    font-size: 40px;
    justify-content: flex-end;
    color: black;
`;

const SubHeader = styled.div`
    padding: 10px;
    font-weight: 500;
    font-size: 25px;
    justify-content: flex-end;
    color: black;
`;

export default Billing;
