import { useEffect, useState } from "react";
import styled from "styled-components";
import MessageModal from "../MessageModal";
import Button from "../Button";
import { userContext } from '../../usercontext';
import Input from "../InputComp";
import { fetchWrapper } from "../../utils/apiHandlers";
import { validateEmail, formatPhoneInput } from "../../utils/helperfunctions";
import { MdContentCopy } from "react-icons/md";
import TextboxComp from "../TextboxComp";
import HorizontalLine from "../horizontalLine";
import InputComp from "../InputComp";
import forge from 'node-forge';
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";
import { Router, useHistory } from "react-router-dom";

interface Props{
}

const Profile: React.FC<Props> = (props) => {

  const history = useHistory()
  
  const [user, setUser] = useState<any>(null) 
  const [ firstname, setfirstname ] = useState("")
  const [ lastname, setlastname ] = useState("")
  const [ phonenumber, setphonenumber ] = useState("")
  const [ address, setaddress ] = useState("")
  const [ passphrase, setpassphrase ] = useState("")
  const [ showpwd, setshowpwd ] = useState(false)
  const [ currpassphrase, setcurrpassphrase ] = useState("")
  const [ newpassphrase, setnewpassphrase ] = useState("")
  const [ showpassphrase, setshowpassphrase ] = useState(false)
  const [ showpassphrase1, setshowpassphrase1 ] = useState(false)
  
  
  const [ secretkey, setsecretkey ] = useState("")
  const installpackage = "npm i miamigochatbot"

  const usecomponent = `  import Miamigochatbot from "miamigochatbot"

  <Miamigochatbot 
    userid="12224"
    supportEmail="support@example.com"
    chatbotname="Support"
    title='AI Chatbot' 
    onSend=(question)=>handle(question) 
    />`

  const [modalheader, setmodalheader] = useState('')
  const [modalmessage, setmodalmessage] = useState('')
  const [modalprogress, setmodalprogress] = useState(false)  
  const [modalstate, setmodalstate] = useState(false)
  const [pwdsent, setpwdsent] = useState(false)
  const [ resetKey, setresetKey ] = useState(false)
  const [ newpassword, setnewpassword ] = useState("")
  const [ oldpassword, setoldpassword ] = useState("")

  const openModal = (header: string, message: string, progress?: boolean) => {
    setmodalheader(header)
    setmodalmessage(message)
    setmodalstate(true)
    setmodalprogress(progress?progress: false)
    
  }

  const update = async () => {
    openModal("Message", "updating", true)
    try{
        let response = await userContext.updateprofile(user.email, firstname, lastname, phonenumber, address )
        if(response){
            openModal("Message", "Profile updated.", false)
        }else{
            openModal("Error", "Failed.", false)
        }

    }catch(error){
        openModal("Error", "Failed.", false)
    }
  }

  const changephonenumber = (e: any) =>{
    if(e.target.value.length>12)return
    const re = /^[0-9\b]+$/;
    let newTxt = e.target.value.replace(/-/g, "");
    let phone = "";
    if (newTxt === "" || re.test(newTxt)) {
      phone = formatPhoneInput(newTxt, [4, 8]);
      setphonenumber(phone);
    }
  }

  const newkey = () =>{
    return new Promise((resolve: any) =>{
      forge.pki.rsa.generateKeyPair({bits: 2048, workers: 2}, (err: any, keypair: any)=>{
        var publicKey = forge.pki.publicKeyToPem(keypair.publicKey);
        
        var privateKey = forge.pki.encryptRsaPrivateKey(keypair.privateKey, passphrase);        

        resolve({publicKey: publicKey, privateKey: privateKey})
      })

    })
  }

  const changeencryptionkeys = () => {
    if(!resetKey){
      openModal("Warning", "Changing encryption keys will make all you current information inaccessible. You should backup your information before proceeding.")
    }
    setresetKey(!resetKey)
  }

  const getnewkey = async () => {

    if(passphrase=="" || passphrase.length<6){
      openModal("Warning", "Please enter strong passphrase", false)
      return
    }
    if(passphrase=="MyGr3atGrandma'sRecipeBook!"){
      openModal("Warning", "This passphrase is not allowed.", false)
      return
    }

    let keys: any = await newkey()

    let response = await fetchWrapper.post("/api/crypto/savekeys", {email: user.email, publickey: keys.publicKey, privatekey: keys.privateKey})
    openModal("Message", "Keys generated", false)
    setsecretkey(response.secret)
    setpassphrase("")
    let pubresponse = await userContext.setpublickey(keys.publicKey)
    if(!pubresponse){
      openModal("Error", "Failed to retrieve public key, please logout and login again", false)
    }


  }
  const changeshowpwd = () =>{
    setshowpwd(!showpwd)
  }

  const toggleshowpwd = () =>{
    setshowpwd(!showpwd)
  }

  const changepassword = async () => {
    if(newpassword.length<8){
      openModal("Warning", "The password must be at least 8 characters long.", false)
      return
    }
    await fetchWrapper.post("/api/authenticate/changepassword", {email:user.email, oldpassword:oldpassword,newpassword:newpassword}).then((r)=> {
      if(r.status==200){
        openModal("Message", "Password chanaged", false)
        return
      }else{
        openModal("Warning", "Failed to change password", false)
        return
      }
    }).catch((error: any)=>{
      openModal("Warning", "Failed to change password", false)
    })
    setoldpassword("")
    setnewpassword("")
  }

  const changepasphrase = async () => {


    if(currpassphrase==""){
      openModal("Warning", "Please enter current passphrase before proceeding", false)
      return
    }
    if(newpassphrase==""){
      openModal("Warning", "Please enter new passphrase before proceeding", false)
      return
    }
    if(newpassphrase.length<16){
      openModal("Warning", "Your passphrase should be at least 16 characters long.", false)
      return
    }
  openModal("Please wait", "", true)

  let privateKey = ""
  let response1 = await fetchWrapper.post("/api/crypto/getprivatekey", {email:user.email})
  privateKey = response1.private_key
  try{
    var privateKey_decoded = forge.pki.decryptRsaPrivateKey(privateKey, currpassphrase)

  }catch(error){
    console.log(`Error : ${error}`)
    openModal("Error", "Incorrect passphrase", false)
    return
  }
  if(!privateKey_decoded){
    console.log(`privateKey_decoded: ${privateKey_decoded}`)
    openModal("Error", "Incorrect passphrase", false)
    return
  }
  

  try{
      var new_encrypted_privateKey = forge.pki.encryptRsaPrivateKey(privateKey_decoded, newpassphrase);

      let response = await fetchWrapper.post("/api/crypto/updatepassphrase", {email: user.email, publickey: user.publicKey, privatekey: new_encrypted_privateKey})
    openModal("Message", "Passphrase changed", false)
    setcurrpassphrase("")
    setnewpassphrase("")
    setshowpassphrase(false)
    setshowpassphrase1(false)
  }catch(error){
    console.log(`Error : ${error}`)
    openModal("Warning", "Failed to change Passphrase", false)
  }
}

  useEffect(() => {
    const subscription = userContext.user.subscribe((x:any) => setUser(x))
    return () => subscription.unsubscribe()
  }, [])

  useEffect(()=>{
    if(!user)return;
    if(user.status!==200){
      history.push("/login")
    }
    setfirstname(user.firstname)
    setlastname(user.lastname)
    setphonenumber(user.phonenumber)
    setaddress(user.address)
  },[user])

  return (
    <>
      <MainContainer>
          <MessageModal header={modalheader} message={modalmessage} state={modalstate} handleClose={()=> setmodalstate(false)} progress={modalprogress} />
          <Header>Profile</Header>
          {user&&user.status==200?<>
            <Input id="useremail" placeholder="email" value={user.email} onChange={(e: any) => {}} />
            <Input id="firstnameid" placeholder="first name" value={firstname} onChange={(e: any) => {setfirstname(e.target.value)}} />
            <Input id="lastnameid" placeholder="last name" value={lastname} onChange={(e: any) => {setlastname(e.target.value)}} />
            <Input id="phonenumberid" placeholder="phone number" value={phonenumber} onChange={changephonenumber} />
            <Input id="addressid" placeholder="address" value={address} onChange={(e)=>setaddress(e.target.value)} />
            <Button handleClick={()=>update()} text="Update" />
            <HorizontalLine />
            <Header>Change Password*</Header>
            <Column style={{padding:0, alignItems: 'flex-start'}}>

                <Input id="oldchangepwdinput" placeholder="current password" onChange={(e)=>setoldpassword(e.target.value)} value={oldpassword} type={"password"} />

                <NewPwdContainer>
                  <Input id="changepwdinput" placeholder="new password" onChange={(e)=>setnewpassword(e.target.value)} value={newpassword} type={showpwd?"text":"password"} /> 
                  <div>
                    {showpwd?<AiOutlineEye onClick={toggleshowpwd}/> : <AiOutlineEyeInvisible onClick={toggleshowpwd}/>}
                  </div>
                </NewPwdContainer>
          </Column>
          <Button handleClick={changepassword} text="Change password" />
          <span style={{fontSize:"12px"}}>*Passwords are to login to Zappletz.com</span>
            <HorizontalLine />
                  <Header>
                    Encryption keys
                  </Header>
            {
              !user.public_key||user.public_key==""||resetKey?
              
              <>

                    <ButtonContainer style={{alignItems:'center'}}>
                      <InputComp id="passphrase_id" value={passphrase} onChange={(e: any)=>setpassphrase(e.target.value)} placeholder="enter passphrase for encryption" type={showpwd?"text":"password"}/>
                      {showpwd?
                        <AiOutlineEye fontSize={20} onClick={()=>changeshowpwd()}/>:
                        <AiOutlineEyeInvisible fontSize={20} onClick={()=>changeshowpwd()}/>
                      }
                    </ButtonContainer>
                    <Button handleClick={()=>getnewkey()} text="get new key" />
                    
                {/* <Label>secret key is only displayed once</Label> */}
                </>:<></>}
                  <Label htmlFor="resetkeyid">
                      <input id="resetkeyid" type="checkbox" checked={resetKey} onChange={changeencryptionkeys}/>
                      Reset Keys (backup data before resetting)
                  </Label>
            </>:<></>}
            <br/>
            <HorizontalLine />
            <Header>
              Change Passphrase**
            </Header>
            {
              <>

                    <ButtonContainer style={{alignItems:'center'}}>
                      <InputComp id="currpassphrase_id" value={currpassphrase} onChange={(e: any)=>setcurrpassphrase(e.target.value)} placeholder="enter current passphrase" type={showpassphrase?"text":"password"}/>
                      {showpassphrase?
                        <AiOutlineEye fontSize={20} onClick={()=>setshowpassphrase(!showpassphrase)}/>:
                        <AiOutlineEyeInvisible fontSize={20} onClick={()=>setshowpassphrase(!showpassphrase)}/>
                      }
                    </ButtonContainer>
                    <ButtonContainer style={{alignItems:'center'}}>
                      <InputComp id="currpassphrase_idq" value={newpassphrase} onChange={(e: any)=>setnewpassphrase(e.target.value)} placeholder="enter new passphrase" type={showpassphrase1?"text":"password"}/>
                      {showpassphrase1?
                        <AiOutlineEye fontSize={20} onClick={()=>setshowpassphrase1(!showpassphrase1)}/>:
                        <AiOutlineEyeInvisible fontSize={20} onClick={()=>setshowpassphrase1(!showpassphrase1)}/>
                      }
                    </ButtonContainer>
                    <Button handleClick={()=>changepasphrase()} text="Change passphrase" />
                <span style={{fontSize:"12px"}}>**Your passphrase decrypts encoded data.</span>
                    
                </>}
                <br/>
                <br/>
      </MainContainer>
    </>
  );
};

const NewPwdContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Textarea = styled.textarea`
    padding: 5px;
    border-radius: 5px;
    user-select: none;
    &:hover{

    }
`;

const CopyIcon = styled.div`
    // margin: 0;
    padding: 3px;
    font-size: 20px;
    &:hover{
        padding: 3px 2px;
        font-size:22px;
        cursor: pointer;
    }
`;

const ButtonContainer = styled.div`
    display: flex;
`;
const Label = styled.label`
    font-size: 12px;
    color: #E18D4B;
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

const InfoRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 5px;
`

const Column = styled.div`
  padding: 50px;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  @media (max-width: 770px) {
    padding: 20px 50px;
  }
`

const Header = styled.div`
    padding: 10px;
    font-weight: 700;
    font-size: 30px;
    justify-content: flex-end;
    color: black;
`;

export default Profile;
