import React, {useState, useEffect} from 'react'
import styled, { keyframes } from 'styled-components'
import MessageModal from "../MessageModal";
import Input from '../InputComp';
import Button from '../Button';
import download from 'downloadjs'
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";
import forge from 'node-forge';
import { fetchWrapper } from '../../utils/apiHandlers';
import { userContext } from '../../usercontext';
import { formatDate } from '../../utils/helperfunctions';
import { useHistory } from 'react-router-dom';

interface Prop{
}
const BackupData : React.FC<Prop>=(props)=>{

    const history = useHistory()
    const [ user, setuser ] = useState<any>(null)
    const [ publickey, setpublickey ] = useState("")
    
    const [modalheader, setmodalheader] = useState('')
    const [modalmessage, setmodalmessage] = useState('')
    const [modalprogress, setmodalprogress] = useState(false)  
    const [modalstate, setmodalstate] = useState(false)
    const [ showpwd, setshowpwd ] = useState(false)
    const [ passphrase, setpassphrase ] = useState("")

    const openModal = (header: string, message: string, progress?: boolean) => {
        setmodalheader(header)
        setmodalmessage(message)
        setmodalstate(true)
        setmodalprogress(progress?progress: false)
        
      }

    const loaditems = async () => {
        openModal("Please wait", "", true)

        let privateKey: any = ""
            let response = await fetchWrapper.post("/api/crypto/getprivatekey", {email:user.email})
            privateKey = response.private_key

        try{
            let privateKey_decoded = forge.pki.decryptRsaPrivateKey(privateKey, passphrase)
            let response = await fetchWrapper.post("/api/crypto/loaditems", {email:user.email, filter: ""})
            
            let items = response.items
            
            for(let i=0; i<items.length; i++){
                items[i].password = forge.util.decodeUtf8(privateKey_decoded.decrypt(forge.util.decode64(items[i].password)))
                for(let j=0; j<items[i].recovery.length; j++){
                    items[i].recovery[j].question = forge.util.decodeUtf8(privateKey_decoded.decrypt(forge.util.decode64(items[i].recovery[j].question)))
                    items[i].recovery[j].answer = forge.util.decodeUtf8(privateKey_decoded.decrypt(forge.util.decode64(items[i].recovery[j].answer)))
                }
                
            }
            setmodalstate(false)
        }catch(error){
            console.log(`error : ${error}`)
            openModal("Warning", "Incorrect passphrase", false)
            setpassphrase("")
        }
    }
    
    const backupdata = async () => {
        if(passphrase==""){
            openModal("Warning", "Passphrase is required", false)
            return
        }
        openModal("Backup up data", "", true)
        let privateKey: any = ""
        let response = await fetchWrapper.post("/api/crypto/getprivatekey", {email:user.email})
        privateKey = response.private_key

        try{
            let privateKey_decoded = forge.pki.decryptRsaPrivateKey(privateKey, passphrase)
            let response = await fetchWrapper.post("/api/crypto/loaditems", {email:user.email, filter: ""})
            
            let items = response.items
            let data = []
            let d = "name,username, password, recovery"
            data.push(d)
            for(let i=0; i<items.length; i++){
                items[i].password = forge.util.decodeUtf8(privateKey_decoded.decrypt(forge.util.decode64(items[i].password)))
                for(let j=0; j<items[i].recovery.length; j++){
                    items[i].recovery[j].question = forge.util.decodeUtf8(privateKey_decoded.decrypt(forge.util.decode64(items[i].recovery[j].question)))
                    items[i].recovery[j].answer = forge.util.decodeUtf8(privateKey_decoded.decrypt(forge.util.decode64(items[i].recovery[j].answer)))
                }
                let d = items[i].name + "," + items[i].username + "," + items[i].password
                for(let j=0; j<items[i].recovery.length; j++){
                    d +=  "," + items[i].recovery[j].question + "," + items[i].recovery[j].answer
                }
                data.push(d)                
            }
            let csv = data.join("\n")
            const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
            let filename = `data_${formatDate(new Date(), "YMd")}.csv`
            download(blob, filename)
            setmodalstate(false)
        }catch(error){
            console.log(`error : ${error}`)
            openModal("Warning", "Incorrect passphrase", false)
        }
        setpassphrase("")
        setshowpwd(false)
    }

    useEffect(() => {
        const subscription = userContext.user.subscribe((x:any) => setuser(x))
        return () => subscription.unsubscribe()
      }, [])
    
      useEffect(()=>{
        if(!user)return;
        if(user.status!==200){
          history.push("/login")
        }else{
            setpublickey(user.public_key)
        }
      },[user])
    
  return(
        <MainContainer>
            <MessageModal header={modalheader} message={modalmessage} state={modalstate} handleClose={()=> setmodalstate(false)} progress={modalprogress} />
                <Column>
                    <Header>Backup Data</Header>
                    <NewPwdContainer>
                    <Input id="changepwdinput" placeholder="passphrase" onChange={(e)=>setpassphrase(e.target.value)} value={passphrase} type={showpwd?"text":"password"} /> 
                    <div>
                        {showpwd?<AiOutlineEye onClick={()=>setshowpwd(!showpwd)}/> : <AiOutlineEyeInvisible onClick={()=>setshowpwd(!showpwd)}/>}
                    </div>
                    </NewPwdContainer>
                    <Button text='Backup' handleClick={backupdata} />
                </Column>
        </MainContainer>
    );
}


const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 85vh;
    justify-content: flex-start;
    align-items: center;
`
const Column = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
`;
const Header = styled.div`
    font-size: 24px;
    font-weight: 900;
`;
const NewPwdContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
export default BackupData