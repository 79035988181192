import { useEffect, useState } from "react"
import styled from "styled-components"
import Button from "../../components/Button"
import CustomModal from "../../components/MessageModal"
import LoadingComponent from "../../components/LoadingComponent"
import { userContext } from "../../usercontext"
import ShowFRUsers from "../../components/ShowFRUsers"
import ShowLogsComp from "../../components/ShowLogsComp"
import { useHistory } from "react-router-dom"


interface Props{

}

const Admin: React.FC<Props> = (props) => {

    const history = useHistory()
    const [ adminuser, setadminuser ] = useState<any>(null)
    const [ adminemail, setadminemail ] = useState("")
    const [ loading, setloading ] = useState(true)
    const [ loadingmessage, setloadingmessage ] = useState("")
    
    const [modalheader, setmodalheader] = useState('');
    const [modalmessage, setmodalmessage] = useState('');
    const [modalstate, setmodalstate] = useState(false);
    const [modalprogress  , setmodalprogress  ] = useState(false);

    const [ showfrusers, setshowfrusers ] = useState(false)    
    const [ showfruserlogscomp, setshowfruserlogscomp ] = useState(false)
    const [ showaddreportcomp, setshowaddreportcomp ] = useState(false)
    const [ showemailcomp, setshowemailcomp ] = useState(false)
    const [ showmakesaplelist, setshowmakesaplelist ] = useState(false)
    const [ showaddbanner, setshowaddbanner ] = useState(false)
    const [ showcreateadaccount, setshowcreateadaccount ] = useState(false)
    const [ showmanualpayment, setshowmanualpayment ] = useState(false)

    const openModal = (header: string, message: string, progress?: boolean, showconfirmmodal?: boolean, confirmmodalhndl?: any) => {
      setmodalheader(header)
      setmodalmessage(message);
      if(progress){
        setmodalprogress(progress)
      }else{
        setmodalprogress(false);
      }
      setmodalstate(true)
    }
  

    useEffect(() => {

    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
        const subscription = userContext.user.subscribe(x => {
            let user = JSON.parse(JSON.stringify(x))
            setadminuser(user)
        });

        return () => {
            subscription.unsubscribe();
        }
    }, [])

    useEffect(()=>{
        if(adminuser){
          if(adminuser.status==200)
          {
              if(adminuser.role=="admin747"){
                setadminemail(adminuser.email);
                setloading(false);
              }else{
                userContext.logout()
              }
          }else{
              history.push("/login");
          }
        }
    }, [adminuser])


    return (
      <MainContainer>
			{/* <Head>
				<title>64cents - Admin</title>
				<meta
				name="description"
				content="64cents"
				/>
				<link rel="shortcut icon" href="../favicon.ico"/>
			</Head> */}
			<CustomModal header={modalheader} message={modalmessage} state={modalstate} handleClose={()=>setmodalstate(false)} progress ={modalprogress} />
			<Header>Admin</Header>
			<ButtonContainer>
			<Button text="Users" backgroundColor={showfrusers?"#9446F2":"#4B9FE1"}  handleClick={()=>setshowfrusers(!showfrusers)} />
			<Button text="Logs" backgroundColor={showfruserlogscomp?"#9446F2":"#4B9FE1"}  handleClick={()=>setshowfruserlogscomp(!showfruserlogscomp)} />
			{/* <Button text="Add News Report" backgroundColor={showaddreportcomp?"#9446F2":"#4B9FE1"}  handleClick={()=>setshowaddreportcomp(!showaddreportcomp)} /> */}
			<Button text="Email Comp" backgroundColor={showemailcomp?"#9446F2":"#4B9FE1"}  handleClick={()=>setshowemailcomp(!showemailcomp)} />
			<Button text="Payment" backgroundColor={showmanualpayment?"#9446F2":"#4B9FE1"}  handleClick={()=>setshowmanualpayment(!showmanualpayment)} />
			</ButtonContainer>
			{loading?
			<LoadingComponent />:<></>
			}
			{
				showfrusers?<ShowFRUsers openModal={openModal}/>:<></>
			}
			{
				showfruserlogscomp?<ShowLogsComp /> :<></>
			}

        </MainContainer>
    )
};

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: #000000;
  min-height: 80vh;
  width: 100%;
  padding: 25px;
  @media (max-width: 750px) {
    padding: 5px;
  }
`
const Column = styled.div`
  padding: 25px;
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  width: 100%;
  @media (max-width: 750px) {
    padding: 15px;
  }
`
const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: 10px;
`;

const Header = styled.div`
  font-weight: 700;
  font-size: 24px;
`;

export default Admin
