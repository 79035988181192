import React, {useState, useEffect} from 'react'
import styled, { keyframes } from 'styled-components';
import InputComp from '../InputComp';
import Button from '../Button';
import { fetchWrapper } from '../../utils/apiHandlers';
import { userContext } from '../../usercontext';
import MessageModal from '../MessageModal';
import ConfirmModal from '../ConfirmModal';
import SmallButton from '../SmallButton';
import forge from 'node-forge';
import { RiDeleteBinLine } from "react-icons/ri";
import { MdEdit } from "react-icons/md";
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";
import DecryptModal from '../DecryptModal';
import ItemCard from '../ItemCardComp';
import HorizontalLine from '../horizontalLine';
import { useHistory } from 'react-router-dom';

interface Props{
}

interface Recovery{
    question?: any,
    answer?: any,
}

const CryptoComp : React.FC<Props>=(props)=>{
    const history = useHistory()

    const [user, setUser] = useState<any>(null) 
    
    const [modalheader, setmodalheader] = useState('')
    const [modalmessage, setmodalmessage] = useState('')
    const [modalprogress, setmodalprogress] = useState(false)  
    const [modalstate, setmodalstate] = useState(false)
    const [ confirmmodalstate, setconfirmmodalstate ] = useState(false)
    const [ deleteitemidx, setdeleteitemidx ] = useState(-1)
    
    
    const [recovery, setrecovery] = useState<[Recovery]|null>(null)
    const [ toggle, settoggle ] = useState(false)
    const [ items, setitems ] = useState<any>(null)
    const [ newlabel, setnewlabel ] = useState("")    
    const [ newusername, setnewusername ] = useState("")
    const [ newvalue, setnewvalue ] = useState("")
    const [ newrecovery, setnewrecovery ] = useState<any>(null)
    const [ publickey, setpublickey ] = useState("")
    

    const [ privatekey, setprivatekey ] = useState("")
    const [ filter, setfilter ] = useState("")
    const [ passphrase, setpassphrase ] = useState("")
    const [ edititemid, setedititemid ] = useState(-1)
    const [ showpwd, setshowpwd ] = useState(false)
    const [ showitempwd, setshowitempwd ] = useState(false)
    
    const [ showitemmodal, setshowitemmodal ] = useState(false)
    const [ item, setitem ] = useState<any>(null)
    
    const [ selectedrow, setselectedrow ] = useState(-1)
    
  
    const openModal = (header: string, message: string, progress?: boolean) => {
      setmodalheader(header)
      setmodalmessage(message)
      setmodalstate(true)
      setmodalprogress(progress?progress: false)
    }

    const clearitems = () => {
        setitems(null)
        setpassphrase("")
        setfilter("")
        navigator.clipboard.writeText("")
    }

    const loaditems = async () => {
        setselectedrow(-1)
        setedititemid(-1)
        setnewlabel("")
        setnewusername("")
        setnewvalue("")
        if(passphrase==""){
            openModal("Warning", "Please enter passphrase before proceeding", false)
            return
        }
        openModal("Please wait", "", true)

        let privateKey = privatekey
        // if(privatekey==""){
            let response = await fetchWrapper.post("/api/crypto/getprivatekey", {email:user.email})
            privateKey = response.private_key
            setprivatekey(privateKey)
        // }



        try{
            let privateKey_decoded = forge.pki.decryptRsaPrivateKey(privateKey, passphrase)
            let response = await fetchWrapper.post("/api/crypto/loaditems", {email:user.email, filter: filter})
            
            let items = response.items
            
            for(let i=0; i<items.length; i++){
                items[i].password = forge.util.decodeUtf8(privateKey_decoded.decrypt(forge.util.decode64(items[i].password)))
                for(let j=0; j<items[i].recovery.length; j++){
                    items[i].recovery[j].question = forge.util.decodeUtf8(privateKey_decoded.decrypt(forge.util.decode64(items[i].recovery[j].question)))
                    items[i].recovery[j].answer = forge.util.decodeUtf8(privateKey_decoded.decrypt(forge.util.decode64(items[i].recovery[j].answer)))
                }
                
            }
            setitems(items)
            setmodalstate(false)
        }catch(error){
            console.log(`error : ${error}`)
            openModal("Warning", "Incorrect passphrase", false)
            setpassphrase("")
        }
    }

    const cancelupdateitem = async () => {
        setedititemid(-1)
        setnewlabel("")
        setnewusername("")
        setnewvalue("")
        setnewrecovery(null)
    }

    const updaterecoveryq = (e: any, id: any) => {
        newrecovery[id].question = e.target.value
        setnewrecovery(newrecovery)
        settoggle(!toggle)
    }
    const updaterecoverya = (e: any, id: any) => {
        newrecovery[id].answer = e.target.value
        setnewrecovery(newrecovery)
        settoggle(!toggle)
    }

    const edititem = async (idx: any) => {
        setedititemid(idx)
        setnewlabel(items[idx].name)
        setnewusername(items[idx].username)
        setnewvalue(items[idx].password)
        let r = []
        
        for(let i=0; i<items[idx].recovery.length; i++){
            r.push({
                _id:items[idx].recovery[i]._id,
                question:items[idx].recovery[i].question,
                answer:items[idx].recovery[i].answer,            
            })
        }
        console.log(`items[idx].recovery : ${JSON.stringify(r)}`)
        setnewrecovery(r)
    }

    const confirmdeleteitem = async (idx: any) => {
        setmodalheader("Confirm delete")
        setmodalmessage("Are you sure you want to delete the entry?")
        setdeleteitemidx(idx)
        setconfirmmodalstate(true)
    }

    const deleteitem = async () => {
        setconfirmmodalstate(false)
        await fetchWrapper.post("/api/crypto/deletepwd", {id: items[deleteitemidx]._id, email: user.email}).then((r: any)=>r)
        loaditems()
        setshowitemmodal(false)
    }

    const enterpressed = (e: any) => {
        if(e.keyCode==13)loaditems()
    }
    const openItemModal = (idx: any) => {
        setitem(items[idx])
        setshowitemmodal(true)
        setselectedrow(idx)
      }

    useEffect(() => {
        setitems(null)
        const subscription = userContext.user.subscribe((x:any) => setUser(x))
        return () => subscription.unsubscribe()
      }, [])
    
      useEffect(()=>{
        if(!user)return;
        if(user.status!==200){
          history.push("/login")
        }else{
            setpublickey(user.public_key)
        }



      },[user])

      useEffect(()=>{

      },[recovery, edititemid, newrecovery])

    return(
        <MainContainer>

            <DecryptModal item={item} state={showitemmodal} handleClose={()=>{setshowitemmodal(false)}} showpassword={showitempwd}
            public_key={publickey} confirmdelete={()=>confirmdeleteitem(selectedrow)} openModal={openModal} closeModal={()=>setmodalstate(false)}
            />

            <MessageModal header={modalheader} message={modalmessage} state={modalstate} handleClose={()=> setmodalstate(false)} progress={modalprogress} />

            <ConfirmModal header={modalheader} message={modalmessage} state={confirmmodalstate} handleClose={()=>setconfirmmodalstate(false)} func={()=>deleteitem()}
            />
            <Column>
                <Header>
                    Retrieve Passwords
                </Header>
                <div onKeyDown={(e: any)=> enterpressed(e)} >
                    <InputComp id="filter" placeholder='filter' value={filter} onChange={(e: any) => setfilter(e.target.value)} />

                    <NewPwdContainer>
                        <InputComp id="passphrase_id" value={passphrase} onChange={(e: any)=>setpassphrase(e.target.value)} placeholder="enter passphrase" type={showpwd?"text":"password"} />

                        {showpwd?<AiOutlineEye onClick={()=>setshowpwd(!showpwd)}/> : <AiOutlineEyeInvisible onClick={()=>setshowpwd(!showpwd)}/>}

                    </NewPwdContainer>
                </div>

                <ButtonContainer>
                    <Button text='load' handleClick={loaditems} />
                    <Button text='clear' handleClick={clearitems} />
                </ButtonContainer>
                <Span>
                    <input id="itempwdshow" type="checkbox" checked={showitempwd} onChange={()=>setshowitempwd(!showitempwd)} /> <Label htmlFor='itempwdshow'>Show Passwords</Label>
                </Span>

                <RowBigScreen>
                {
                    items&&items.length>0?items.map((v: any, idx: any) => (
                        <ItemCard item={v} showpassword={showitempwd} key={`itemcompkey${idx}`} public_key={user.public_key} confirmdelete={()=>confirmdeleteitem(idx)} openModal={openModal} email={user.email} closeModal={()=>setmodalstate(false)} />
                    )):<></>
                }
                </RowBigScreen>

                <RowSmallScreen>
                {
                    items&&items.length>0?
                    <ItemHeader style={{color: "#FF5578"}}>Click row for details</ItemHeader>
                    :<></>
                }
                </RowSmallScreen>
                <RowSmallScreen style={{alignItems:"flex-start"}}>
                {
                    items&&items.length>0?items.map((v: any, idx: any) => (
                        <div key={`item${idx}`} style={{width:"100%"}}>
                            <ItemHeader key={`itemamallcompkey${idx}`} onClick={()=>openItemModal(idx)}>{`${idx+1}`} &nbsp; {`${v.name}`}</ItemHeader>
                            <HorizontalLine />
                        </div>
                    )):<></>
                }
                </RowSmallScreen>
            </Column>

        </MainContainer>
    );
}

const Span = styled.span`
    padding: 7px;
`;

const ButtonContainer = styled.div`
    display: flex;
`;
const Label = styled.label`
    font-size: 14px;
    color: #000;
    user-select: none;
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
`

const InfoRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 5px;
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  @media (max-width: 770px) {
    padding: 20px 10px;
  }
`

const Header = styled.div`
    padding: 10px;
    font-weight: 700;
    font-size: 25px;
    justify-content: flex-end;
    color: black;
`;

const RowHead = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  margin: 0px;
  padding: 3px;
  border-bottom: 1px solid black;
  &:hover{
    background-color: #eee;
  }
  @media (max-width: 770px) {
    flex-direction: column;
  }
`

const RowBigScreen = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: 0px;
  padding: 3px;
  flex-wrap: wrap;
  @media (max-width: 650px) {
    display: none;
  }
`

const RowSmallScreen = styled.div`
  display: none;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: 0px;
  padding: 3px;
  flex-wrap: wrap;
  @media (max-width: 650px) {
    display: flex;
    flex-direction: column;
  }
`

const Row = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: 0px;
  padding: 3px;
  flex-wrap: wrap;
  @media (max-width: 770px) {
    flex-direction: column;
  }
`

const ItemHeader = styled.div`
    display: flex;
    flex: 1;
    padding: 5px;
    font-weight: 700;
    font-size: 14px;
    color: black;
`;

const NewPwdContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default CryptoComp