import React, {useState, useEffect, useRef} from 'react'
import styled, { keyframes } from 'styled-components'
import MessageModal from '../../components/MessageModal'
import Button from '../../components/SmallButton'
import { MdOutlineRotate90DegreesCcw, MdOutlineRotate90DegreesCw } from "react-icons/md";
import InputComp from '../../components/InputComp';
import IncrementDecrementComp from '../../components/IncrementDecrementComp';

interface Prop{
}
const PhotoEditor : React.FC<Prop>=({})=>{

    const [ canvaswidth, setcanvaswidth ] = useState(500)
    const [ canvasheight, setcanvasheight ] = useState(500)  

    const [ filename, setfilename ] = useState("")
    const [ file, setFile ] = useState("")
    const [modalheader, setmodalheader] = useState('')
    const [modalmessage, setmodalmessage] = useState('')
    const [modalprogress, setmodalprogress] = useState(false)  
    const [modalstate, setmodalstate] = useState(false)
    const canvasRef: any = useRef();
    const [scale, setScale] = useState(1); // Default zoom scale
    const [offset, setOffset] = useState({ x: 0, y: 0 }); // Offset for panning
    const [isDragging, setIsDragging] = useState(false);
    const [lastMousePosition, setLastMousePosition] = useState({ x: 0, y: 0 });
    const [image, setImage] = useState<HTMLImageElement | null>(null);

    const [ text, settext ] = useState("")    
    const [ fontSize, setfontSize ] = useState(20)
    const [ textColor, settextColor ] = useState("#000000")
    const [ inserttext, setinserttext ] = useState(false)
    const [ canvasbackground, setcanvasbackground ] = useState("#F0F0F0")
    const [ transparentbg, settransparentbg ] = useState(false)
    

    const [ squarecanvas, setsquarecanvas ] = useState(true)
    const [ hcanvas, sethcanvas ] = useState(false)
    const [ vcanvas, setvcanvas ] = useState(false)

    const openModal = (header: string, message: string, progress?: boolean) => {
        setmodalheader(header)
        setmodalmessage(message)
        setmodalstate(true)
        setmodalprogress(progress?progress: false)
        
    }

    const handleFileChange = async (event: any) => {
        const selectedFile = event.target.files[0];
        const context = canvasRef.current?.getContext("2d");
        const canvas = canvasRef.current;
        try{
            if (selectedFile && selectedFile.type.includes("image")) {
                setfilename(selectedFile.name)
                setFile(selectedFile);
                const imageUrl = URL.createObjectURL(selectedFile); // Create a URL for the file
                const img = new Image();

                img.onload = () => {
                    setImage(img)
                    if (context && canvas) {
                        const { width, height } = calculateAspectRatio(img, canvas.width, canvas.height);

                        context.clearRect(0, 0, canvas.width, canvas.height);
                        setScale(1); // Reset scale on new image load
                        setOffset({ x: (canvas.width - width) / 2, y: (canvas.height - height) / 2 }); // Center image
                        context.drawImage(img, (canvas.width - width) / 2, (canvas.height - height) / 2, width, height);
                    }
                };
                img.src = imageUrl; // Set the source of the Image object
            } else {
                openModal("Warning", "Please upload a valid image file.");
                setfilename("")
                setImage(null)
                context.clearRect(0, 0, canvas.width, canvas.height);
            }
        }catch(error){
            console.log(`error : ${error}`)
            setfilename("")
            setImage(null)
            context.clearRect(0, 0, canvas.width, canvas.height);
        }
    }

    const toJpg = async () => {
        if (!canvasRef.current) return;
        const canvas = canvasRef.current;
        const link = document.createElement("a");

        // Save the canvas content as a JPEG file
        const finalImage = canvas.toDataURL("image/jpeg", 0.9); // 90% quality for JPG
        link.href = finalImage;
        link.download = `zappletz-${filename}.jpg`;

        link.click();

        return
        // // Create a temporary canvas for cropping
        // const tempCanvas = document.createElement("canvas");
        // tempCanvas.width = canvas.width;
        // tempCanvas.height = canvas.height;
        // const tempContext = tempCanvas.getContext("2d");

        // if (tempContext) {
        //     let width = canvas.width
        //     let height = canvas.height
        //     if(image){
        //         let dim = calculateAspectRatio(image, canvas.width, canvas.height);
        //         width = dim.width
        //         height = dim.height
        //     }

        //     const { x, y } = offset;

        //     tempContext.save();
        //     tempContext.translate(x, y);
        //     tempContext.scale(scale, scale);
        //     if(image){
        //         tempContext.drawImage(image, 0, 0, width, height);
        //     }
        //     tempContext.restore();

        //     // Convert the cropped canvas content to an image
        //     const croppedImage = tempCanvas.toDataURL("image/jpeg");

        //     // Create a download link
        //     const link = document.createElement("a");
        //     link.href = croppedImage;
        //     link.download = `zappletz-${filename}.jpg`
        //     link.click();
        // }
    }

    const toPng = async () => {
        if (!canvasRef.current) return;
        const canvas = canvasRef.current;
        const link = document.createElement("a");

        // Save the canvas content as a JPEG file
        const finalImage = canvas.toDataURL("image/png", 0.9); // 90% quality for JPG
        link.href = finalImage;
        link.download = `zappletz-${filename}.png`;

        link.click();

        // // Create a temporary canvas for cropping
        // const tempCanvas = document.createElement("canvas");
        // tempCanvas.width = canvas.width;
        // tempCanvas.height = canvas.height;
        // const tempContext = tempCanvas.getContext("2d");

        // if (tempContext && image) {
        //     const { width, height } = calculateAspectRatio(image, canvas.width, canvas.height);
        //     const { x, y } = offset;

        //     tempContext.save();
        //     tempContext.translate(x, y);
        //     tempContext.scale(scale, scale);
        //     tempContext.drawImage(image, 0, 0, width, height);
        //     tempContext.restore();

        //     // Convert the cropped canvas content to an image
        //     const croppedImage = tempCanvas.toDataURL("image/png");

        //     // Create a download link
        //     const link = document.createElement("a");
        //     link.href = croppedImage;
        //     let name = filename.split(".")
        //     link.download = `zappletz-${filename}.png`
        //     link.click();
        // }
    }

    const clear = () => {
        setfilename("")
        setImage(null)
        const context = canvasRef.current?.getContext("2d");
        const canvas = canvasRef.current;
        context.clearRect(0, 0, canvas.width, canvas.height);
    }

    const calculateAspectRatio = (img: HTMLImageElement, canvasWidth: number, canvasHeight: number) => {
        const imgAspectRatio = img.width / img.height;
        const canvasAspectRatio = canvasWidth / canvasHeight;
        if (imgAspectRatio > canvasAspectRatio) {
            // Fit to canvas width
            const width = canvasWidth;
            const height = canvasWidth / imgAspectRatio;
            return { width, height };
        } else {
            // Fit to canvas height
            const height = canvasHeight;
            const width = canvasHeight * imgAspectRatio;
            return { width, height };
        }
    }

    const redrawCanvas = () => {
        if (!image || !canvasRef.current) return;
        const context = canvasRef.current.getContext("2d");
        const canvas = canvasRef.current;
        if (context) {
            const { width, height } = calculateAspectRatio(image, canvas.width, canvas.height);
            const { x, y } = offset;
            context.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
            context.save();
            context.translate(x, y);
            context.scale(scale, scale);
            context.drawImage(image, 0, 0, width, height); // Adjust dimensions as needed
            context.restore();
        }
    };

    const zoomIn = () => {
        setScale((prevScale) => Math.min(prevScale + 0.1, 10)); // Limit maximum zoom level
        redrawCanvas();
    }

    const zoomOut = () => {
        setScale((prevScale) => Math.max(prevScale - 0.1, 0.5)); // Limit minimum zoom level
        redrawCanvas();
    }

    const writeText = async (event: any, xpos: any, ypos: any) => {

        if (!inserttext||!canvasRef.current) return;
        const canvas = canvasRef.current;
        const rect = canvas.getBoundingClientRect();
        const context = canvas.getContext("2d");
        if (context) {
            context.save();
            context.font = `${fontSize}px Arial`; // Set font size and style
            context.fillStyle = textColor; // Set text color
            context.fillText(text, xpos, ypos); // Draw text at specified position
            context.restore();
        }
    }

    const handleTouchDown = (event: any) => {
        event.preventDefault();
        setIsDragging(true);
        setLastMousePosition({ x: event.touches[0].clientX, y: event.touches[0].clientY });

        const canvas = canvasRef.current;
        const rect = canvas.getBoundingClientRect();
        const context = canvas.getContext("2d");
        context.moveTo(event.clientX-rect.left, event.clientY-rect.top);
        let xpos = event.clientX-rect.left
        let ypos = event.clientY-rect.top
        writeText(event, xpos, ypos)
    }

    const handleMouseDown = (event: React.MouseEvent) => {
        event.preventDefault();
        setIsDragging(true);
        setLastMousePosition({ x: event.clientX, y: event.clientY });

        const canvas = canvasRef.current;
        const rect = canvas.getBoundingClientRect();
        const context = canvas.getContext("2d");
        context.moveTo(event.clientX-rect.left, event.clientY-rect.top);
        let xpos = event.clientX-rect.left
        let ypos = event.clientY-rect.top
        writeText(event, xpos, ypos)
    }

    const handleTouchMove = (event: any) => {
        if (!isDragging) return;
        event.preventDefault();
        const dx = event.touches[0].clientX - lastMousePosition.x;
        const dy = event.touches[0].clientY - lastMousePosition.y;
        setOffset((prevOffset) => ({ x: prevOffset.x + dx, y: prevOffset.y + dy }));
        setLastMousePosition({ x: event.touches[0].clientX, y: event.touches[0].clientY });
        redrawCanvas();
    }

    const handleMouseMove = (event: React.MouseEvent) => {
        if (!isDragging) return;
        event.preventDefault();
        const dx = event.clientX - lastMousePosition.x;
        const dy = event.clientY - lastMousePosition.y;
        setOffset((prevOffset) => ({ x: prevOffset.x + dx, y: prevOffset.y + dy }));
        setLastMousePosition({ x: event.clientX, y: event.clientY });
        redrawCanvas();
    }

    const handleMouseUp = () => {
        setIsDragging(false);
    }

    const rotateImage = (angle: any) => {
        if (!canvasRef.current || !image) return;
        const canvas = canvasRef.current;
        const context = canvas.getContext("2d");
    
        if (context) {
            // Create a temporary canvas for rotating
            const tempCanvas = document.createElement("canvas");
            const tempContext = tempCanvas.getContext("2d");
    
            if (tempContext) {
                // Adjust the temporary canvas dimensions for rotation
                tempCanvas.width = canvas.height;
                tempCanvas.height = canvas.width;
    
                // Rotate the image by 90 degrees clockwise
                tempContext.translate(tempCanvas.width / 2, tempCanvas.height / 2);
                tempContext.rotate((angle * Math.PI) / 180); // 90 degrees in radians
                tempContext.drawImage(
                    canvas,
                    -canvas.width / 2,
                    -canvas.height / 2,
                    canvas.width,
                    canvas.height
                );
    
                // Resize the main canvas and copy the rotated content back
                canvas.width = tempCanvas.width;
                canvas.height = tempCanvas.height;
                context.clearRect(0, 0, canvas.width, canvas.height);
                context.drawImage(tempCanvas, 0, 0);
            }
        }
    };
    

    const saveImage = () => {
        if (!canvasRef.current) return;
        const canvas = canvasRef.current;

        // Create a temporary canvas for cropping
        const tempCanvas = document.createElement("canvas");
        tempCanvas.width = canvas.width;
        tempCanvas.height = canvas.height;
        const tempContext = tempCanvas.getContext("2d");

        if (tempContext && image) {
            const { width, height } = calculateAspectRatio(image, canvas.width, canvas.height);
            const { x, y } = offset;

            tempContext.save();
            tempContext.translate(x, y);
            tempContext.scale(scale, scale);
            tempContext.drawImage(image, 0, 0, width, height);
            tempContext.restore();

            // Convert the cropped canvas content to an image
            const croppedImage = tempCanvas.toDataURL("image/png");

            // Create a download link
            const link = document.createElement("a");
            link.href = croppedImage;
            let name = filename.split(".")
            link.download = `zappletz-${filename}.png`
            link.click();
        }
    };

    const savePassportImage = () => {
        if (!canvasRef.current) return;
        const canvas = canvasRef.current;

        // Create a temporary canvas for the 600x400 output
        const tempCanvas = document.createElement("canvas");
        tempCanvas.width = 600;
        tempCanvas.height = 400;
        const tempContext = tempCanvas.getContext("2d");

        if (tempContext && canvas) {
            const editedCanvas = document.createElement("canvas");
            const editedContext = editedCanvas.getContext("2d");

            // Set up the size of the edited canvas to match the current canvas
            editedCanvas.width = canvas.width;
            editedCanvas.height = canvas.height;

            if (editedContext && image) {
                // Draw the edited version of the image onto the temporary canvas
                const { width, height } = calculateAspectRatio(image, canvas.width, canvas.height);
                const { x, y } = offset;

                editedContext.clearRect(0, 0, canvas.width, canvas.height);
                editedContext.save();
                editedContext.translate(x, y);
                editedContext.scale(scale, scale);
                editedContext.drawImage(image, 0, 0, width, height);
                editedContext.restore();

                // Use the edited canvas image to draw 6 times on the final canvas
                const outputWidth = 200; // Width of each image in the grid
                const outputHeight = 200; // Height of each image in the grid

                for (let i = 0; i < 3; i++) {
                    for (let j = 0; j < 2; j++) {
                        const xPos = i * outputWidth;
                        const yPos = j * outputHeight;

                        tempContext.drawImage(
                            editedCanvas, // Use the edited canvas as the source
                            0, // Source X
                            0, // Source Y
                            canvas.width, // Source width (full canvas width)
                            canvas.height, // Source height (full canvas height)
                            xPos, // Destination X
                            yPos, // Destination Y
                            outputWidth, // Destination width
                            outputHeight // Destination height
                        );
                    }
                }

                // Convert the final canvas content to an image
                const finalImage = tempCanvas.toDataURL("image/png");

                // Create a download link
                const link = document.createElement("a");
                link.href = finalImage;
                link.download = `zappletz-passport-${filename || "edited"}.png`;
                link.click();
            }
        }
    };

    const customCanvas = (type: any) => {
        clear()
        switch(type){
            case "square":
                const body: any = document.getElementsByTagName("body")
                Array.prototype.forEach.call(body, x => {
                    setcanvaswidth(Math.min(500, x.clientWidth*0.8))
                    setcanvasheight(Math.min(500, x.clientWidth*0.8))
                });
                setsquarecanvas(true)
                sethcanvas(false)
                setvcanvas(false)
                break
            case "hbanner":
                setcanvaswidth(728)
                setcanvasheight(150)
                setsquarecanvas(false)
                sethcanvas(true)
                setvcanvas(false)
                break
            case "vbanner":
                setcanvaswidth(150)
                setcanvasheight(728)
                setsquarecanvas(false)
                sethcanvas(false)
                setvcanvas(true)
                break
        }
    }

    const changefontsize = (e: any) =>{
        let n = Number(e.target.value)
        if(isNaN(n) || n==0 || n>1000) return
        setfontSize(n)
    }

    const upfunc = () =>{
        setfontSize(fontSize+1)
    }

    const downfunc = () =>{
        if(fontSize==1) return
        setfontSize(fontSize-1)
    }

    useEffect(()=>{
        const body: any = document.getElementsByTagName("body")
        Array.prototype.forEach.call(body, x => {
            setcanvaswidth(Math.min(500, x.clientWidth*0.8))
            setcanvasheight(Math.min(500, x.clientWidth*0.8))
        });
        
    }, [])

    useEffect(()=>{
        const canvas = canvasRef.current
        var ctx = canvas.getContext("2d")
        ctx.fillStyle = canvasbackground
        ctx.fillRect(0, 0, canvas.width, canvas.height)
    }, [ canvasbackground])

    useEffect(()=>{

    }, [canvaswidth])

    return(
        <MainContainer>
            <Column>
            <MessageModal header={modalheader} message={modalmessage} state={modalstate} handleClose={()=> setmodalstate(false)} progress={modalprogress} />
            <Header>Photo APP</Header>
            <SubHeader>Create and Edit images and banners</SubHeader>
            <Row style={{padding: "20px 0"}}>
                <Label htmlFor="uploadfileinfo"> 
                Select Image
                </Label>
                <UploadInput
                    id="uploadfileinfo"
                    type="file"
                    accept="image" 
                    onChange={handleFileChange}
                />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {filename==""?"":filename}
                <br/>
            </Row>

            <Row style={{userSelect:"none"}}>
                <span>
                    <input id="square" type='checkbox' onChange={()=>customCanvas("square")} checked={squarecanvas} />
                    <label htmlFor='square'>Square</label>
                </span>
                &nbsp;&nbsp;
                <span>
                    <input id="horizontal" type='checkbox' onChange={()=>customCanvas("hbanner")} checked={hcanvas} />
                    <label htmlFor='horizontal'>Horizontal</label>
                </span>
                &nbsp;&nbsp;
                <span>
                    <input id="vertical" type='checkbox' onChange={()=>customCanvas("vbanner")} checked={vcanvas} />
                    <label htmlFor='vertical'>Vertical</label>
                </span>
            </Row>
            <Row>

                <InputComp id="insertbackgroundcolorinput" placeholder='background color' value={canvasbackground} onChange={(e: any)=>setcanvasbackground(e.target.value)} width={100}/>
                <span>
                    <input id="transparentbg" type='checkbox' onChange={(e: any)=>settransparentbg(!transparentbg)} checked={transparentbg} />
                    <label htmlFor='transparentbg'>transparent</label>
                </span>
                    
            </Row>
            <Row style={{height:"80px", flexWrap:"wrap"}}>
                <span>
                    <input id="inserttext" type='checkbox' onChange={(e: any)=>setinserttext(!inserttext)} checked={inserttext} />
                    <label htmlFor='inserttext'>insert text</label>
                </span>
                    
                    {inserttext?<>
                        <InputComp id="inserttextinput" placeholder='insert text' value={text} onChange={(e: any)=>settext(e.target.value)} />
                        <Button text='clear' handleClick={(e: any) => settext("")} />
                        <span style={{display: 'flex'}}>
                            <InputComp  id={`input_id`} value={`${fontSize}`} onChange={(e)=>{changefontsize(e)}} placeholder='font size' width={50} />
                            <IncrementDecrementComp upfunc={upfunc} downfunc={downfunc}/>
                        </span>
                    </>
                    :<></>
                    }

                    
                    {inserttext?<>
                        <InputComp id="inserttextcolorinput" placeholder='text color' value={textColor} onChange={(e: any)=>settextColor(e.target.value)} width={75}/>
                        </>
                        :<></>
                    }
            </Row>

            <canvas
                ref={canvasRef}
                style={{ border: "1px solid black", cursor: isDragging ? "grabbing" : "grab",width: `${canvaswidth}px`, height: `${canvasheight}px`, touchAction: "none", }}
                onMouseDown={handleMouseDown}
                onMouseMove={handleMouseMove}
                onMouseUp={handleMouseUp}
                onMouseLeave={handleMouseUp}
                onTouchStart={handleTouchDown}
                onTouchMove={handleTouchMove}
                onTouchEnd={handleMouseUp}
                width={canvaswidth}
                height={canvasheight}                
            />
            <br/>
            <ButtonContainer>
                <Button handleClick={clear} text="clear" />
                <Button handleClick={zoomIn} text="Zoom In" />
                <Button handleClick={zoomOut} text="Zoom Out" />
                <Button handleClick={toJpg} text="save jpg" />
                <Button handleClick={toPng} text="save png" />
                <Button handleClick={savePassportImage} text="passport" />&nbsp;&nbsp;
                <MdOutlineRotate90DegreesCcw fontSize={"20px"} onClick={()=>rotateImage(-90)}/>&nbsp;&nbsp;
                <MdOutlineRotate90DegreesCw fontSize={"20px"} onClick={()=>rotateImage(90)}/>
            </ButtonContainer>

            </Column>
        </MainContainer>
    );
}

const ButtonContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    user-select: none;
`;


const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 85vh;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 20px;
`
const Column = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 10px;
`;
const Row = styled.div`
    display: flex;
    align-items: center;
    padding: 5px;
    user-select: none;
`;
const Header = styled.div`
    font-size: 4.5rem;
    font-weight: 700;
`;
const SubHeader = styled.div`
    font-size: 2rem;
    font-weight: 700;
`;

const UploadInput = styled.input`
  margin: 5px;
  text-align: center;
  opacity: 0;
  display: none;
`;

const Label = styled.label`
  color: #4B9FE1;
  margin: 5px;
  background-color: #eee;
  font-size: 16px;
  flex-wrap: wrap;
  padding: 7px;
  border-radius: 3px;
  cursor: pointer;
  &:hover {
    font-weight: 700;
  }
`;
export default PhotoEditor